<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <card class="form-horizontal">
          <!-- <h5 slot="header">Booking</h5> -->
          <tabs value="Description" tab-content-classes="text-center">
            <tab-pane title="Participant" key="participant">
              <h4>Company Details</h4>

              <!-- old -->

              <!-- <div class="form-group">
                        <label class="control-label" for="production_id">Production</label>
                        <select class="form-control" id="production_id" value.bind="formModel.production_id & validate" disabled.bind="!isNewParticipant">
                            <option repeat.for="option of productions_select" model.bind="option.value">${option.name}</option>
                        </select>
                    </div> -->

              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">Company Name</label>
                <div class="col-sm-4">
                  <fg-input
                    type="text"
                    name="participant_company"
                    v-validate="modelValidations.participant_company"
                    :error="getError('participant_company')"
                    v-model="model.participant_company"
                  >
                  </fg-input>
                </div>
              </div>

              <div class="row fatrow">
                <label class="col-sm-2 col-form-label"
                  >Original Company Name</label
                >
                <div class="col-sm-4">
                  <fg-input
                    type="text"
                    name="participant_company_original"
                    v-validate="modelValidations.participant_company_original"
                    :error="getError('participant_company_original')"
                    v-model="model.participant_company_original"
                  >
                  </fg-input>
                </div>
              </div>

              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">Listing Letter</label>
                <div class="col-sm-2">
                  <el-select
                    class="select-default"
                    size="large"
                    placeholder=""
                    v-model="model.participant_letter"
                  >
                    <el-option
                      v-for="option in alphabet"
                      class="select-primary"
                      :value="option"
                      :label="option"
                      :key="option"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>

              <div class="row fatrow">
                <label class="col-sm-2 col-form-label"
                  >External Reference</label
                >
                <div class="col-sm-4">
                  <fg-input
                    type="text"
                    name="participant_ext_ref"
                    v-validate="modelValidations.participant_ext_ref"
                    :error="getError('participant_ext_ref')"
                    v-model="model.participant_ext_ref"
                  >
                  </fg-input>
                </div>
              </div>

              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">Telephone</label>
                <div class="col-sm-4">
                  <fg-input
                    type="text"
                    name="participant_telephone"
                    v-validate="modelValidations.participant_telephone"
                    :error="getError('participant_telephone')"
                    v-model="model.participant_telephone"
                  >
                  </fg-input>
                </div>
              </div>
              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">Fax</label>
                <div class="col-sm-4">
                  <fg-input
                    type="text"
                    name="participant_fax"
                    v-validate="modelValidations.participant_fax"
                    :error="getError('participant_fax')"
                    v-model="model.participant_fax"
                  >
                  </fg-input>
                </div>
              </div>
              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">Email</label>
                <div class="col-sm-4">
                  <fg-input
                    type="text"
                    name="participant_email"
                    v-validate="modelValidations.participant_email"
                    :error="getError('participant_email')"
                    v-model="model.participant_email"
                  >
                  </fg-input>
                </div>
              </div>

              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">Hide Rates</label>
                <div class="col-sm-2">
                  <el-select
                    class="select-default"
                    size="large"
                    placeholder=""
                    v-model="model.participant_hide_rates"
                  >
                    <el-option
                      v-for="option in YesNo"
                      class="select-primary"
                      :value="option.value"
                      :label="option.label"
                      :key="option.label"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">Address</label>
                <div class="col-sm-4">
                  <fg-input
                    type="text"
                    name="participant_address_line_1"
                    v-validate="modelValidations.participant_address_line_1"
                    :error="getError('participant_address_line_1')"
                    v-model="model.participant_address_line_1"
                  >
                  </fg-input>
                </div>
              </div>
              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">Address</label>
                <div class="col-sm-4">
                  <fg-input
                    type="text"
                    name="participant_address_line_2"
                    v-validate="modelValidations.participant_address_line_2"
                    :error="getError('participant_address_line_2')"
                    v-model="model.participant_address_line_2"
                  >
                  </fg-input>
                </div>
              </div>
              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">Address</label>
                <div class="col-sm-4">
                  <fg-input
                    type="text"
                    name="participant_address_line_3"
                    v-validate="modelValidations.participant_address_line_3"
                    :error="getError('participant_address_line_3')"
                    v-model="model.participant_address_line_3"
                  >
                  </fg-input>
                </div>
              </div>

              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">Town</label>
                <div class="col-sm-4">
                  <fg-input
                    type="text"
                    name="participant_address_town"
                    v-validate="modelValidations.participant_address_town"
                    :error="getError('participant_address_town')"
                    v-model="model.participant_address_town"
                  >
                  </fg-input>
                </div>
              </div>
              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">County / State</label>
                <div class="col-sm-4">
                  <fg-input
                    type="text"
                    name="participant_address_county"
                    v-validate="modelValidations.participant_address_county"
                    :error="getError('participant_address_county')"
                    v-model="model.participant_address_county"
                  >
                  </fg-input>
                </div>
              </div>
              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">Post Code / Zip</label>
                <div class="col-sm-4">
                  <fg-input
                    type="text"
                    name="participant_address_post_code"
                    v-validate="modelValidations.participant_address_post_code"
                    :error="getError('participant_address_post_code')"
                    v-model="model.participant_address_post_code"
                  >
                  </fg-input>
                </div>
              </div>

              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">Country</label>
                <div class="col-sm-4">
                  <el-select
                    class="select-default"
                    size="large"
                    placeholder=""
                    v-model="model.participant_address_country"
                  >
                    <el-option
                      v-for="option in countries"
                      class="select-primary"
                      :value="option.name"
                      :label="option.name"
                      :key="option.name"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">Website</label>
                <div class="col-sm-4">
                  <fg-input
                    type="text"
                    name="participant_url"
                    v-validate="modelValidations.participant_url"
                    :error="getError('participant_url')"
                    v-model="model.participant_url"
                  >
                  </fg-input>
                </div>
              </div>
              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">Twitter Account</label>
                <div class="col-sm-4">
                  <fg-input
                    type="text"
                    name="twitteraccount"
                    v-validate="modelValidations.twitteraccount"
                    :error="getError('twitteraccount')"
                    v-model="model.twitteraccount"
                  >
                  </fg-input>
                </div>
              </div>
              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">Facebook</label>
                <div class="col-sm-4">
                  <fg-input
                    type="text"
                    name="facebook"
                    v-validate="modelValidations.facebook"
                    :error="getError('facebook')"
                    v-model="model.facebook"
                  >
                  </fg-input>
                </div>
              </div>
              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">Instagram</label>
                <div class="col-sm-4">
                  <fg-input
                    type="text"
                    name="instagram"
                    v-validate="modelValidations.instagram"
                    :error="getError('instagram')"
                    v-model="model.instagram"
                  >
                  </fg-input>
                </div>
              </div>

              <hr />
              <h4>Form Contact</h4>

              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">Form Contact</label>
                <div class="col-sm-4">
                  <fg-input
                    type="text"
                    name="participant_form_contact"
                    v-validate="modelValidations.participant_form_contact"
                    :error="getError('participant_form_contact')"
                    v-model="model.participant_form_contact"
                  >
                  </fg-input>
                </div>
              </div>

              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">Contact Position</label>
                <div class="col-sm-4">
                  <fg-input
                    type="text"
                    name="participant_contact_position"
                    v-validate="modelValidations.participant_contact_position"
                    :error="getError('participant_contact_position')"
                    v-model="model.participant_contact_position"
                  >
                  </fg-input>
                </div>
              </div>
              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">Contact Email</label>
                <div class="col-sm-4">
                  <fg-input
                    type="text"
                    name="form_contact_email"
                    v-validate="modelValidations.form_contact_email"
                    :error="getError('form_contact_email')"
                    v-model="model.form_contact_email"
                  >
                  </fg-input>
                </div>
              </div>
              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">Contact Telephone</label>
                <div class="col-sm-4">
                  <fg-input
                    type="text"
                    name="form_contact_tel"
                    v-validate="modelValidations.form_contact_tel"
                    :error="getError('form_contact_tel')"
                    v-model="model.form_contact_tel"
                  >
                  </fg-input>
                </div>
              </div>

              <div class="row fatrow">
                <!-- <label class="col-md-3"></label> -->
                <div class="col-md-9">
                  <p-button type="info" @click.prevent="generateOTP">
                    Generate OTP
                  </p-button>
                  <p-button type="info" @click.prevent="addToMailChimpAudience">
                    Add to MailChimp Audience
                  </p-button>
                </div>
              </div>

              <div class="row fatrow" v-show="showOTPUrl">
                <label class="col-sm-2 col-form-label">OTP URL</label>
                <div class="col-sm-4">
                  <fg-input
                    type="text"
                    name="opt_url"
                    v-model="otpModel.otpUrl"
                  >
                  </fg-input>
                </div>
              </div>

              <hr />
              <h4>Show Details</h4>

              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">Stand</label>
                <div class="col-sm-4">
                  <fg-input
                    type="text"
                    name="participant_stand"
                    v-validate="modelValidations.participant_stand"
                    :error="getError('participant_stand')"
                    v-model="model.participant_stand"
                  >
                  </fg-input>
                </div>
              </div>
              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">Hall</label>
                <div class="col-sm-4">
                  <fg-input
                    type="text"
                    name="participant_hall"
                    v-validate="modelValidations.participant_hall"
                    :error="getError('participant_hall')"
                    v-model="model.participant_hall"
                  >
                  </fg-input>
                </div>
              </div>
              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">Sector</label>
                <div class="col-sm-4">
                  <fg-input
                    type="text"
                    name="participant_sector"
                    v-validate="modelValidations.participant_sector"
                    :error="getError('participant_sector')"
                    v-model="model.participant_sector"
                  >
                  </fg-input>
                </div>
              </div>
              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">Sector Code</label>
                <div class="col-sm-4">
                  <fg-input
                    type="text"
                    name="sector_code"
                    v-validate="modelValidations.sector_code"
                    :error="getError('sector_code')"
                    v-model="model.sector_code"
                  >
                  </fg-input>
                </div>
              </div>
              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">Stand Type</label>
                <div class="col-sm-2">
                  <el-select
                    class="select-default"
                    size="large"
                    placeholder=""
                    v-model="model.participant_stand_type"
                  >
                    <el-option
                      v-for="option in standTypes"
                      class="select-primary"
                      :value="option.value"
                      :label="option.text"
                      :key="option.text"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">Sharer Status</label>
                <div class="col-sm-2">
                  <el-select
                    class="select-default"
                    size="large"
                    placeholder=""
                    v-model="model.participant_sharing_status"
                  >
                    <el-option
                      v-for="option in sharingTypes"
                      class="select-primary"
                      :value="option.value"
                      :label="option.text"
                      :key="option.text"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>

              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">Show Zone</label>
                <div class="col-sm-4">
                  <fg-input
                    type="text"
                    name="showzone"
                    v-validate="modelValidations.showzone"
                    :error="getError('showzone')"
                    v-model="model.showzone"
                  >
                  </fg-input>
                </div>
              </div>
              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">Pavilion</label>
                <div class="col-sm-4">
                  <fg-input
                    type="text"
                    name="pavilion"
                    v-validate="modelValidations.pavilion"
                    :error="getError('pavilion')"
                    v-model="model.pavilion"
                  >
                  </fg-input>
                </div>
              </div>
              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">Showroom URL</label>
                <div class="col-sm-4">
                  <fg-input
                    type="text"
                    name="showroomurl"
                    v-validate="modelValidations.showroomurl"
                    :error="getError('showroomurl')"
                    v-model="model.showroomurl"
                  >
                  </fg-input>
                </div>
              </div>

              <hr />
              <h4>Production Details</h4>

              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">Batch</label>
                <div class="col-sm-1">
                  <fg-input
                    type="text"
                    name="participant_batch"
                    v-validate="modelValidations.participant_batch"
                    :error="getError('participant_batch')"
                    v-model="model.participant_batch"
                  >
                  </fg-input>
                </div>
              </div>
              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">Creation Date</label>
                <div class="col-sm-2">
                  <el-date-picker
                    v-model="model.participant_creation_date"
                    type="datetime"
                    placeholder="Select date and time"
                  ></el-date-picker>
                </div>
              </div>
              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">Amended Date</label>
                <div class="col-sm-2">
                  <el-date-picker
                    v-model="model.participant_amended_date"
                    type="datetime"
                    placeholder="Select date and time"
                  ></el-date-picker>
                </div>
              </div>

              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">Has Bought</label>
                <div class="col-sm-2">
                  <el-select
                    class="select-default"
                    size="large"
                    placeholder=""
                    v-model="model.participant_bought"
                  >
                    <el-option
                      v-for="option in bought"
                      class="select-primary"
                      :value="option.value"
                      :label="option.text"
                      :key="option.text"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="row fatrow">
                <label class="col-sm-2 col-form-label"
                  >Action Outstanding</label
                >
                <div class="col-sm-2">
                  <el-select
                    class="select-default"
                    size="large"
                    placeholder=""
                    v-model="model.participant_action_outstanding"
                  >
                    <el-option
                      v-for="option in YesNo"
                      class="select-primary"
                      :value="option.value"
                      :label="option.label"
                      :key="option.label"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">Entry Complete</label>
                <div class="col-sm-2">
                  <el-select
                    class="select-default"
                    size="large"
                    placeholder=""
                    v-model="model.participant_entry_complete"
                  >
                    <el-option
                      v-for="option in YesNo"
                      class="select-primary"
                      :value="option.value"
                      :label="option.label"
                      :key="option.label"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">Products Complete</label>
                <div class="col-sm-2">
                  <el-select
                    class="select-default"
                    size="large"
                    placeholder=""
                    v-model="model.participant_prods_complete"
                  >
                    <el-option
                      v-for="option in YesNo"
                      class="select-primary"
                      :value="option.value"
                      :label="option.label"
                      :key="option.label"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">Text Complete</label>
                <div class="col-sm-2">
                  <el-select
                    class="select-default"
                    size="large"
                    placeholder=""
                    v-model="model.participant_text_complete"
                  >
                    <el-option
                      v-for="option in YesNo"
                      class="select-primary"
                      :value="option.value"
                      :label="option.label"
                      :key="option.label"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>

              <div class="row fatrow">
                <!-- <label class="col-md-3"></label> -->
                <div class="col-md-9">
                  <p-button type="info" @click.prevent="validate">
                    Save
                  </p-button>
                </div>
              </div>
            </tab-pane>
            <tab-pane title="Products" key="products">
              <!-- <div class="pre-scrollable" style="max-height: 70vh"> -->

              <!-- <h6 class="card-title pl-3">Products Selected</h6> -->
              <div class="row">
                <div class="col-sm-4">
                  <div class="pull-left">
                    {{ selectedProductsCount }} selected out of a possible
                    {{ maxProducts }}
                  </div>
                </div>
                <div class="col-sm-5"></div>
                <!-- <div class="col-sm-2">

                </div> -->
                <div class="col-sm-3">
                  <div class="pull-right">
                    <div class="btn-group">
                      <p-button
                        type="primary"
                        @click="saveParticipantProducts()"
                        >Save Products</p-button
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-4">
                  <div class="pull-left">
                    <loading-progress
                      :progress="productProgress"
                      size="300"
                      height="20"
                      fillDuration="4"
                      rotationDuration="1"
                      shape="line"
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12">
                  <vue-good-table
                    :theme="tableTheme"
                    :rowStyleClass="rowStyleClassFn"
                    max-height="70vh"
                    :fixed-header="true"
                    :columns="participantProductColumns"
                    :rows="participantProducts"
                  >
                    <template slot="table-row" slot-scope="props">
                      <span v-if="props.column.field == 'actions'">
                        <p-button
                          type="info"
                          size="sm"
                          v-if="props.row.selected"
                          icon
                          @click="handleProductClick(props.$index, props.row)"
                        >
                          <i class="fa fa-check"></i>
                        </p-button>
                        <p-button
                          type="info"
                          size="sm"
                          v-else
                          icon
                          @click="handleProductClick(props.$index, props.row)"
                        >
                          <i class="fa fa-square-o"></i>
                        </p-button>
                      </span>
                      <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                      </span>
                    </template>
                  </vue-good-table>
                </div>
              </div>
            </tab-pane>
            <tab-pane title="Text Entry" key="text">
              <!-- <h6 class="card-title pl-3">Text Entry</h6> -->
              <div class="row">
                <div class="col-sm-4">
                  <div class="pull-left">
                    {{ textStats.words }} words (characters:
                    {{ textStats.characters }}) added out of a possible
                    {{ production_max_words }}
                  </div>
                </div>
                <div class="col-sm-5"></div>
                <div class="col-sm-3">
                  <div class="pull-right">
                    <div class="btn-group">
                      <p-button type="primary" @click="saveParticipantText()"
                        >Save Text</p-button
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-4">
                  <div class="pull-left">
                    <loading-progress
                      :progress="textProgress"
                      size="300"
                      height="20"
                      fillDuration="4"
                      rotationDuration="1"
                      shape="line"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-9">
                  <vue-countable
                    :text="participant_text_model.participant_text"
                    :elementId="'mymyTextBarIdParticipantId'"
                    @change="textChange"
                  ></vue-countable>
                  <form>
                    <div class="form-group">
                      <label>Text Entry</label>
                      <el-input
                        type="textarea"
                        rows="10"
                        v-model="participant_text_model.participant_text"
                      ></el-input>
                    </div>
                  </form>
                </div>
              </div>
            </tab-pane>
          </tabs>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import * as CONSTS from "../../../../../consts/constants";
import { Tabs, TabPane, Card, Modal } from "src/components/UIComponents";
import swal from "sweetalert2";
import {
  DatePicker,
  TimeSelect,
  Slider,
  Tag,
  Input,
  Button,
  Select,
  Option,
} from "element-ui";
import VueCountable from "vue-countable";
//Vue.component('vue-countable', VueCountable)

// import countrySelect from 'vue-country-select'

import { mapGetters } from "vuex";
var numeral = require("numeral");

// create a dictionary for localised labels in forms
const dictionary = {
  en: {
    attributes: {
      production_id: "production",
      participant_company: "company name",
      participant_letter: "listing letter",
      participant_company_original: "original company name",
      participant_ext_ref: "external reference",
      participant_stand: "stand",
      participant_hall: "hall",
      participant_sector: "sector",
      sector_code: "sector code",
      participant_stand_type: "stand type",
      participant_sharing_status: "sharing status",
      participant_hide_rates: "hide rates",
      participant_address_line_1: "address line 1",
      participant_address_line_2: "address line 2",
      participant_address_line_3: "address line 3",
      participant_address_town: "town",
      participant_address_county: "county",
      participant_address_post_code: "postcode / zip",
      participant_address_country: "country",
      participant_telephone: "telephone",
      participant_fax: "fax",
      participant_url: "website",
      twitteraccount: "twitter",
      facebook: "facebook",
      instagram: "Instagram",
      participant_contact: "contact",
      participant_batch: "batch",
      participant_creation_date: "creation date",
      participant_amended_date: "amended date",
      participant_bought: "has bought",
      participant_form_contact: "form contact",
      participant_contact_position: "contact position",
      participant_email: "email",
      form_contact_tel: "telephone",
      participant_action_outstanding: "action outstanding",
      participant_entry_complete: "entry complete",
      participant_prods_complete: "products complete",
      participant_text_complete: "text complete",
      showzone: "show zone",
      pavilion: "pavillion",
      showroomurl: "showroom URL",
      form_contact_email: "form contact email",
    },
  },
};

export default {
  computed: {
    ...mapGetters([
      "currentUser",
      "userSelectedProduction",
      "userSelectedProductionInt",
      "userSelectedProductionCcy",
      "tableTheme",
    ]),
    isDisabled() {
      // evaluate whatever you need to determine disabled here...
      return this.mode == CONSTS.UPDATE ? true : false;
      // return ;
    },
  },
  components: {
    VueCountable,
    Card,
    TabPane,
    Tabs,
    Modal,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Slider.name]: Slider,
    [Tag.name]: Tag,
    [Input.name]: Input,
    [Button.name]: Button,
    [Option.name]: Option,
    [Select.name]: Select,
  },
  data() {
    return {
      participantProductColumns: [
        {
          label: "Select",
          field: "actions",
          width: "50px",
        },
        {
          field: "product_type",
          label: "Type",
          width: "150px",
          tdClass: "tablecell",
          thClass: "tablecell",
          formatFn: this.formatProductType,
          filterOptions: {
            enabled: true,
            placeholder: "Filter",
            filterDropdownItems: CONSTS.strings.product_type,
          },
        },
        {
          field: "product_id",
          label: "Number",
          width: "80px",
          tdClass: "tablecell",
          thClass: "tablecell",
          filterOptions: {
            enabled: true,
            placeholder: "Filter",
          },
        },
        {
          field: "product_name",
          label: "Product",
          width: "200px",
          tdClass: "tablecell",
          thClass: "tablecell",
          filterOptions: {
            enabled: true,
            placeholder: "Filter",
          },
        },
      ],

      mode: CONSTS.UPDATE,
      id: "",
      participantProducts: [],
      timeNow: null,
      showOTPUrl: false,
      maxProducts: 0,
      selectedProductsCount: 0,
      productProgress: 0,
      standTypes: CONSTS.strings.stand_types,
      YesNo: CONSTS.strings.no_yes_label,
      sharingTypes: CONSTS.strings.sharing_types,
      alphabet: CONSTS.strings.alphabet,
      countries: CONSTS.strings.countries,
      bought: CONSTS.strings.bought,
      productType: CONSTS.strings.product_type,
      production_max_words: 0,
      textProgress: 0,

      textStats: {
        paragraphs: 0,
        sentences: 0,
        words: 0,
        characters: 0,
        all: 0,
      },
      participant_text_model: {
        production_id: 0,
        participant_id: 0,
        participant_text: "",
        record_id: 0,
        guid: "",
        participant_guid: "",
      },

      otpModel: {
        form_contact_email: "",
        participant_guid: "",
        otpUrl: "",
      },

      mailChimp: {
        email_address: "",
        contact_name: "",
      },

      model: {
        // participant
        production_id: 0,
        participant_company: "",
        participant_letter: "",
        participant_company_original: "",
        participant_ext_ref: "",
        participant_stand: "",
        participant_hall: "",
        participant_sector: "",
        sector_code: "",
        participant_stand_type: null,
        participant_sharing_status: null,
        participant_hide_rates: null,
        participant_address_line_1: "",
        participant_address_line_2: "",
        participant_address_line_3: "",
        participant_address_town: "",
        participant_address_county: "",
        participant_address_post_code: "",
        participant_address_country: "",
        participant_telephone: "",
        participant_fax: "",
        participant_url: "",
        twitteraccount: "",
        facebook: "",
        instagram: "",
        participant_contact: "",
        participant_batch: null,
        participant_creation_date: null,
        participant_amended_date: null,
        participant_bought: 0,
        participant_form_contact: "",
        participant_contact_position: "",
        form_contact_email: "",
        participant_email: "",
        form_contact_tel: "",
        participant_action_outstanding: 0,
        participant_entry_complete: 0,
        participant_prods_complete: 0,
        participant_text_complete: 0,
        showzone: "",
        pavilion: "",
        showroomurl: "",
      },
      modelValidations: {
        production_id: { numeric: true, required: true },
        participant_company: { required: true, max: 240 },
        participant_letter: { required: true, max: 1 },
        participant_company_original: { required: true, max: 240 },
        participant_ext_ref: { max: 65 },
        participant_stand: { max: 220 },
        participant_hall: { max: 120 },
        participant_sector: { max: 100 },
        sector_code: { max: 10 },
        participant_stand_type: { numeric: true, min_value: 0 },
        participant_sharing_status: { numeric: true, min_value: 0 },
        participant_hide_rates: { numeric: true, min_value: 0 },
        participant_address_line_1: { max: 90 },
        participant_address_line_2: { max: 140 },
        participant_address_line_3: { max: 140 },
        participant_address_town: { max: 40 },
        participant_address_county: { max: 40 },
        participant_address_post_code: { max: 40 },
        participant_address_country: { max: 30 },
        participant_telephone: { max: 55 },
        participant_fax: { max: 55 },
        participant_url: { max: 80, url: true },
        twitteraccount: { max: 120 },
        facebook: { max: 120 },
        instagram: { max: 120 },
        participant_contact: { max: 80 },
        participant_batch: { numeric: true, min_value: 0 },
        // participant_creation_date: { max: 65 },
        // participant_amended_date: null,
        participant_bought: { numeric: true, min_value: 0 },
        participant_form_contact: { max: 80 },
        participant_contact_position: { max: 90 },
        participant_email: { max: 80, email: true },
        form_contact_tel: { max: 30 },
        participant_action_outstanding: { numeric: true, min_value: 0 },
        participant_entry_complete: { numeric: true, min_value: 0 },
        participant_prods_complete: { numeric: true, min_value: 0 },
        participant_text_complete: { numeric: true, min_value: 0 },
        showzone: { max: 120 },
        pavilion: { max: 120 },
        showroomurl: { max: 120, url: true },
        form_contact_email: { max: 80, email: true },
      },
    };
  },
  created() {
    if (this.$route.params.id === undefined) {
      this.mode = CONSTS.NEW;
    } else {
      this.id = this.$route.params.id;
      this.mode = CONSTS.UPDATE;
    }
    // set up a dictionary for the EN form labels
    this.$validator.localize(dictionary);
  },
  mounted() {
    // main record

    if (this.mode == CONSTS.UPDATE) {
      this.$http
        .get("participant/" + this.id)
        .then((response) => {
          if (
            response.data.hasOwnProperty("apiresponse") &&
            response.data.apiresponse.status !== "success"
          ) {
            this.$notify({
              message:
                "There was a problem loading the participant. Please try again. [1]",
              type: "warning",
              icon: "nc-icon nc-alert-circle-i",
              horizontalAlign: "center",
              verticalAlign: "top",
            });
          } else {
            this.model = response.data;
            this.loadParticipantProducts();
            this.loadParticipantText();
          }
        })
        .catch((error) => {
          // console.log(error)
          this.$notify({
            message:
              "There was a problem loading the participant. Please try again. [2]",
            type: "warning",
            icon: "nc-icon nc-alert-circle-i",
            horizontalAlign: "center",
            verticalAlign: "top",
          });
        });
    }
  },

  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    formatDate(value) {
      return this.$moment(value).format("DD-MM-YYYY");
    },

    searchArray: function (array, key, prop) {
      // Optional, but fallback to key['name'] if not selected
      prop = typeof prop === "undefined" ? "name" : prop;

      for (var i = 0; i < array.length; i++) {
        if (array[i][prop] === key) {
          return array[i];
        }
      }
      return null;
    },

    formatType: function (value) {
      let obj = this.searchArray(CONSTS.strings.ad_type, value, "value");
      if (obj) {
        return obj.text;
      }
    },
    updateRecord() {
      let vm = this;

      this.model.participant_amended_date = this.$moment();
      this.model.participant_creation_date = this.$moment(
        this.model.participant_creation_date
      ).format("YYYY-MM-DD HH:mm:ss");
      if (this.model.participant_amended_date)
        this.model.participant_amended_date = this.$moment(
          this.model.participant_amended_date
        ).format("YYYY-MM-DD HH:mm:ss");

      this.$http
        .put("participant/" + this.id, this.model)
        .then(function (response) {
          if (
            response.data.hasOwnProperty("apiresponse") &&
            response.data.apiresponse.status !== "success"
          ) {
            vm.$notify({
              message:
                "There was a problem saving the participant. Please try again. Error code: " +
                response.data.apiresponse.code,
              type: "warning",
              icon: "nc-icon nc-alert-circle-i",
              horizontalAlign: "center",
              verticalAlign: "top",
            });
          } else {
            vm.$notify({
              message: "participant saved",
              type: "success",
              icon: "nc-icon nc-check-2",
              horizontalAlign: "center",
              verticalAlign: "top",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
          vm.$notify({
            message:
              "There was a problem saving the participant. Please try again.",
            type: "warning",
            icon: "nc-icon nc-alert-circle-i",
            horizontalAlign: "center",
            verticalAlign: "top",
          });
        });
    },

    insertClient() {
      let vm = this;

      alert("not implemented yet!");
    },
    validate() {
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          if (this.mode == CONSTS.UPDATE) {
            this.updateRecord();
          } else {
            this.insertClient();
          }
        } else {
          this.$notify({
            message: "Please correct the validation issues and resubmit.",
            type: "warning",
          });
        }
      });
    },

    formatProductType: function (value) {
      let obj = this.searchArray(CONSTS.strings.product_type, value, "value");
      if (obj) {
        return obj.text;
      }
    },

    rowStyleClassFn(row) {
      return row.selected > 0 ? "selectedProd" : "unselectedProd";
    },

    saveParticipantProducts() {
      let selectedProducts = [];

      for (var i = 0; i < this.participantProducts.length; i++) {
        if (this.participantProducts[i].selected > 0) {
          selectedProducts.push(this.participantProducts[i]);
        }
      }

      let vm = this;

      this.$http
        .post("participant/" + this.id + "/product", selectedProducts)
        .then(function (response) {
          if (
            response.data.hasOwnProperty("apiresponse") &&
            response.data.apiresponse.status !== "success"
          ) {
            vm.$notify({
              message:
                "There was a problem saving the products. Please try again. Error code: " +
                response.data.apiresponse.code,
              type: "warning",
            });
          } else {
            vm.$notify({
              message: "Products saved",
              type: "success",
              icon: "nc-icon nc-check-2",
              horizontalAlign: "center",
              verticalAlign: "top",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
          vm.$notify({
            message:
              "There was a problem saving the products. Please try again.",
            type: "danger",
          });
        });
    },

    calculateProgressBarStats(current, max) {
      let progress = 0;
      if (current && max) {
        progress = current / (max / 100) / 100;
      }
      return progress;
    },
    saveParticipantText() {
      let vm = this;

      this.$http
        .put(
          "participant/" + this.id + "/textentry",
          this.participant_text_model
        )
        .then(function (response) {
          if (
            response.data.hasOwnProperty("apiresponse") &&
            response.data.apiresponse.status !== "success"
          ) {
            vm.$notify({
              message:
                "There was a problem saving the participant text. Please try again. Error code: " +
                response.data.apiresponse.code,
              type: "warning",
              icon: "nc-icon nc-alert-circle-i",
              horizontalAlign: "center",
              verticalAlign: "top",
            });
          } else {
            vm.$notify({
              message: "participant text saved",
              type: "success",
              icon: "nc-icon nc-check-2",
              horizontalAlign: "center",
              verticalAlign: "top",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
          vm.$notify({
            message:
              "There was a problem saving the participant text. Please try again.",
            type: "warning",
            icon: "nc-icon nc-alert-circle-i",
            horizontalAlign: "center",
            verticalAlign: "top",
          });
        });
    },
    loadParticipantText() {
      let vm = this;
      this.$http
        .get("participant/" + this.id + "/textentry")
        .then((response) => {
          this.participant_text_model = response.data;
          this.production_max_words = response.data.production_max_words;

          if (this.participant_text_model.participant_text === null) {
            this.participant_text_model.participant_text = "";
          }

          // this.calculateProgressBarStats()
        })
        .catch((error) => {
          // console.log(error)
          vm.$notify({
            message:
              "There was a problem loading the participant text. Please try again.",
            type: "warning",
          });
        });
    },

    addToMailChimpAudience() {
      let vm = this;

      vm.showOTPUrl = false;

      this.mailChimp.email_address = this.model.form_contact_email;
      this.mailChimp.contact_name = this.model.participant_form_contact;


      // this.otpModel.participant_guid = this.id;
      // this.otpModel.form_contact_email = this.model.form_contact_email;

      this.$http
        .post("participant/" + this.id + "/audience", this.mailChimp)
        .then((response) => {
          if (response.data.hasOwnProperty("apiresponse")) {


            if (response.data.apiresponse.status === "success") {
              // vm.otpModel.otpUrl =
              //   "https://forms.fizzzmedia.com/#/setpassword/?otp=" +
              //   response.data.apiresponse.reponseData.otpId;

              vm.$notify({
                message: "Participant added to mailchimp",
                type: "success",
                timeout: 10000,
                horizontalAlign: "center",
              });

              // vm.showOTPUrl = true
            } else {


              vm.$notify({
                message: response.data.apiresponse.msg,
                type: "danger",
                timeout: 10000,
                horizontalAlign: "center",
              });
            }
          } else {


            vm.$notify({
            message:
              "There was a problem adding the participant to Mailchimp. Please try again.",
            type: "warning",
          });
          }
        })
        .catch((error) => {
          // console.log(error)


          vm.$notify({
            message:
              "There was a problem adding the participant to Mailchimp. Please try again.",
            type: "warning",
          });
        });
    },

    generateOTP() {
      let vm = this;

      vm.showOTPUrl = false;

      this.otpModel.participant_guid = this.id;
      this.otpModel.form_contact_email = this.model.form_contact_email;

      this.$http
        .post("participant/otp", this.otpModel)
        .then((response) => {
          if (
            response.data.hasOwnProperty("apiresponse") &&
            response.data.apiresponse.status === "success"
          ) {
            vm.otpModel.otpUrl =
              "https://forms.fizzzmedia.com/#/setpassword/?otp=" +
              response.data.apiresponse.reponseData.otpId;

            vm.$notify({
              message: "OTP created",
              type: "success",
              timeout: 10000,
              horizontalAlign: "center",
            });

            vm.showOTPUrl = true;
          }
        })
        .catch((error) => {
          // console.log(error)
          vm.$notify({
            message: "There was a problem creating the OTP. Please try again.",
            type: "warning",
          });
        });
    },
    loadParticipantProducts() {
      let vm = this;
      this.$http
        .get("participant/" + this.id + "/product")
        .then((response) => {
          this.participantProducts = response.data[1];
          this.selectedProductsCount = 0;

          for (var i = 0; i < this.participantProducts.length; i++) {
            if (this.participantProducts[i].selected > 0) {
              this.selectedProductsCount++;
            }
          }

          this.maxProducts = response.data[0][0].production_max_products;
          this.productProgress = this.calculateProgressBarStats(
            this.selectedProductsCount,
            this.maxProducts
          );
        })
        .catch((error) => {
          // console.log(error)
          vm.$notify({
            message:
              "There was a problem loading the participant products. Please try again.",
            type: "warning",
          });
        });
    },

    textChange(event) {
      this.textStats = event;
      //console.log(event)

      this.textProgress = this.calculateProgressBarStats(
        this.textStats.words,
        this.production_max_words
      );
    },
    handleProductClick(index, row) {
      let productsIndex = this.participantProducts.findIndex(
        (tableRow) => tableRow.product_id === row.product_id
      );

      if (row.selected) {
        // row.selected = 0
        this.participantProducts[productsIndex].selected = 0;
        if (this.selectedProductsCount) this.selectedProductsCount--;
      } else {
        if (this.selectedProductsCount + 1 > this.maxProducts) {
          this.$notify({
            message:
              "You have selected the maximum number of products available!",
            type: "danger",
            icon: "nc-icon nc-alert-circle-i",
            horizontalAlign: "center",
            verticalAlign: "top",
          });
        } else {
          // row.selected = row.product_id
          this.participantProducts[
            productsIndex
          ].selected = this.participantProducts[productsIndex].product_id;
          this.selectedProductsCount++;
        }
      }
      this.productProgress = this.calculateProgressBarStats(
        this.selectedProductsCount,
        this.maxProducts
      );
    },
  },
};
</script>
<style lang="scss">
.inline {
  display: inline-block;
}

.el-select {
  display: block;
}

.swal2-modal {
  height: 600px;
}

/*   div.row { height: 60px; } */

div.fatrow {
  height: 60px;
}

div.superfatrow {
  height: 100px;
}

.divider div {
  height: 25px;
}

.right {
  text-align: right;
}

.tablecell {
  font-size: 14px;
}

.unselectedProd {
  color: #606266;
}

.selectedProd {
  background-color: rgba(185, 225, 25, 0.3); //#b9e119;
  color: #ffffff;
}

.vue-progress-path path {
  stroke-width: 12;
}

.vue-progress-path .progress {
  stroke: #b9e119;
}

.vue-progress-path .background {
  stroke: #f2f4f7;
}

.bg-blue {
  background-color: lightblue;
}
</style>

